import React, { FC } from "react"
import { graphql, HeadFC, PageProps } from "gatsby";
import { BaseLayout } from "@layouts";
import { GatsbyImage } from "gatsby-plugin-image";
import { HouseOnFire, Partykrasher, Restless } from "@releases";
import PlayButton from "@icons/play-button.svg"
import "./index.scss"
import { titleCase } from "@utilities";
import { SEO } from "@components";

type ReleasesData = Queries.ReleasesPageQuery
type ReleasesContext = {
    locale: string
}

const Releases: FC<PageProps<ReleasesData, ReleasesContext>> = ( { data } ) => {
    return (
        <BaseLayout>
            <h1>Releases</h1>
            <div className={ 'bg--striped' }>
                <article className={ "section flow" }>
                    <ul className={ "grid" } role={ 'list' } data-layout={ 'albums' }>
                        { data.albums.nodes.map( ( album ) =>
                            <li className={ 'album' } key={ album.name }>
                                <GatsbyImage
                                    alt={ titleCase( album.name.slice( 3 ) ) } objectFit={ 'cover' } className={ "album__cover" }
                                    image={ album.childImageSharp?.gatsbyImageData! }
                                />
                                { spotifyLinks[album.name as keyof typeof spotifyLinks] &&
                                    <a className={ 'album__play-button' }
                                       title={ `Open ${ titleCase( album.name.slice( 3 ) ) } on Spotify` }
                                       href={ spotifyLinks[album.name as keyof typeof spotifyLinks]! }
                                       target={ '_blank' }
                                       rel={ 'noopener noreferrer' }
                                    >
                                        <span className={ 'sr-only' }>Open { titleCase( album.name.slice( 3 ) ) } on Spotify</span>
                                        <PlayButton/>
                                    </a>
                                }
                            </li>
                        ) }
                    </ul>
                </article>
            </div>
            <Partykrasher/>
            <Restless/>
            <HouseOnFire/>
        </BaseLayout>
    )
}

export default Releases

export const Head: HeadFC = ( { location } ) =>
    <SEO
        title={ "Releases & Achievements" }
        pathname={ location.pathname }
        description={ "Maplerun's music releases (albums, digital singles, live releases) as well as their most significant achievements regarding touring, festival appearances, video game placements and more." }
    />


export const query = graphql`
    query ReleasesPage {
        albums: allFile(filter: {relativeDirectory: {eq: "albums"}}, sort: {fields: name, order: DESC}) {
            nodes {
                name
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    }`

const spotifyLinks = {
    '01-house-on-fire'                 : 'https://open.spotify.com/album/2rdeGLKebDGMdhWRwKYeTE?si=vW7glwJ0Qhm2qc5YD1hwCg',
    '02-tour-ep'                       : null,
    '03-restless'                      : 'https://open.spotify.com/album/7GCeItEa8nClhKoQLwVoCO?si=wqcvDK1PRqSc7Q6kAVtSBA',
    '04-restless-deluxe'               : null,
    '05-acoustic-show'                 : 'https://open.spotify.com/album/7dkGeX13VY5J5B9vqVBEoY?si=uDap5A_vRniyPFY8uZzXtQ',
    '05-buried-alive-single'           : 'https://open.spotify.com/track/02mXJhiMRogf5IkhOYkqEs?si=7b98c0e4678347a6',
    '06-partykrasher'                  : 'https://open.spotify.com/album/6qAORpQ0FsCJ5HxW4XphDS?si=Uyu3rCE_Rf2jp1njBgqDeQ',
    '07-everytime-remix'               : 'https://open.spotify.com/album/6gGfIFT4kWirjjoGHjKLq5?si=vOLo0G_oSZmuqFazJ3yGFw',
    '07-mother-of-mountaines-single'   : 'https://open.spotify.com/album/5C2zojQ861hbsSwKMohBUB?si=GAoOenf0TOaLM2BwgCU_tw',
    '08-krawl-single'                  : 'https://open.spotify.com/album/3SxBzkdJuIDsC5lAVFDdtm?si=0_2GkPYHQUKSaxYWoqxXQA',
    '09-mother-of-oceans-part-1-single': 'https://open.spotify.com/album/4t8KNKEwFL0MMvkyqcWZ76?si=9IMONyMURKCE6RJ6FF62yg',
    '10-mother-of-oceans-part-2-single': 'https://open.spotify.com/album/2D6oRe59Jx2R0KJnkGuSTT?si=6pe5Gs6cRtynGL10nAjMnw',
    '11-okeanos-single'                : 'https://open.spotify.com/album/3iZBWqAI6IIOOcMfd4rRKC?si=pZOd62NSSB2-wkl438pAiA'
}

