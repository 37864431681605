import React, { FC, PropsWithChildren } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Quote } from "@components";

type TypeHouseOnFire = PropsWithChildren<{}>

export const HouseOnFire: FC<TypeHouseOnFire> = () => {
    const query = useStaticQuery( graphql`
        {
            cover: file(relativePath: {eq: "albums/01-house-on-fire.jpg"}) {
                childImageSharp {
                    gatsbyImageData(width: 300, height: 263)
                }
            }
            rockband: file(relativePath: {eq: "house-on-fire/rockband.jpg"}) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            poster: file(relativePath: {eq: "house-on-fire/poster-shadow-gallery.jpg"}) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    ` )

    return (
        <article id={ 'house-on-fire' }>
            <h2 className={ 'album-title text-center' }>House on Fire</h2>
            <div className={ 'grid container box' } data-layout={ '30-70' } data-box-size={'lg'} data-box-padding={'lg'}>
                <GatsbyImage
                    alt={ '' } objectFit={ 'contain' }
                    image={ query.cover?.childImageSharp?.gatsbyImageData! }
                />
                <p>The band spent 4 months between September and December 2007, in order to record what would be their debut album called ‘HOUSE ON FIRE’. The album, produced by the band itself,
                    allowed
                    Maplerun to appear live on Greek television (Mad Day Live on Mad TV and Radio Arvila on Ant1 TV). The band received significant radio play and toured Belgium in November 2009 as
                    well
                    as Greece in spring 2010 and winter 2011. Since then, Maplerun’s video for their single ‘For You’ is being broadcasted on MTV Greece and the band inked a management deal with the
                    host
                    of ‘Headbanger’s Ball’ which is presented on MTV Greece.</p>
            </div>

            <div className={ 'box' } data-theme={ 'light' } data-box-padding={'lg'}>
                <Quote data={ quotes[1] }/>
            </div>

            <div className={ 'grid container box' } data-layout={ '60-40' } style={ { alignSelf: 'end' } } data-box-padding={'lg'}>
                <div className={ 'flow' }>
                    <GatsbyImage
                        alt={ '' } objectFit={ 'contain' }
                        image={ query.rockband?.childImageSharp?.gatsbyImageData! }
                    />
                    <p>The band embarked on a European tour in early October 2010, successfully supporting progressive metal legends SHADOW GALLERY.</p>
                    <p>Τhe band’s single ‘For You’ is presented on Rock Band, a videogame developed by MTV Games and Harmonix, available for Playstation 3, Xbox 360 and Nintendo Wii.</p>
                    <Quote data={ quotes[2] }/>
                </div>
                <GatsbyImage
                    alt={ '' } objectFit={ 'contain' }
                    image={ query.poster?.childImageSharp?.gatsbyImageData! }
                />
            </div>
        </article>
    )
}


const quotes = {
    1: {
        text : "…Already British radio stations have picked up on Maplerun's rough edged hard hitting Nu-Metal and the future looks good. Watch out for them.",
        cite : "METAL EXPRESS RADIO",
        extra: "NORWAY"
    },
    2: {
        text : "...I am really happy to witness the existence of Greek bands like Maplerun, who have the ability to directly compete with big names of the international scene.",
        cite : "ROCKHARD MAGAZINE (GREECE) 7.5/10",
        extra: "Feb ’09/ Issue 39"
    },
}
